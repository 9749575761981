const birthDateWarning = () => {
  const date = +new Date($('#users_profile_natural_birth_date').val());
  const age = ~~((Date.now() - date) / (31557600000));

  if (age < 18 && date) {
    $('#birth-date-warning').removeClass('d-none');
    $('#users_profile_natural_birth_date').parent('.form-group').addClass('has-warning');
  } else {
    $('#birth-date-warning').addClass('d-none');
    $('#users_profile_natural_birth_date').parent('.form-group').removeClass('has-warning');
  }
}

$(document).ready(() => {
  birthDateWarning();
  $('#users_profile_natural_birth_date').on('change', birthDateWarning);
});

