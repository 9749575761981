const confirmationTextCheck = () => {
  let $confirmationTextTextarea = $('textarea#confirmation_text');
  let $confirmationTextWarning = $('#confirmation-text-warning');

  if ($confirmationTextTextarea[0] && $confirmationTextWarning[0]) {
    if ($confirmationTextTextarea.val().trim() === $('#confirmation-text-model').text().trim()) {
      $confirmationTextWarning.addClass('d-none');
      $('form input[type="submit"]').attr('disabled', null);
      $confirmationTextTextarea.removeClass('is-invalid');
      $confirmationTextTextarea.addClass('is-valid');
    } else if ($confirmationTextTextarea.val()) {
      $confirmationTextWarning.removeClass('d-none');
      $('form input[type="submit"]').attr('disabled', 'disabled');
      $confirmationTextTextarea.addClass('is-invalid');
      $confirmationTextTextarea.removeClass('is-valid');
    }
  }
};

$(document).ready(() => {
  $('textarea#confirmation_text').on('input', confirmationTextCheck);
  $('#confirmation-text-model').on('change', confirmationTextCheck);
});
