import {
  getQueryStringParameter,
  showNumberInputsOnLoad
} from '../../../helpers';

const showOtherInput = input => {
  const $otherInputParent = $(input).parents('.row').next('.row');

  $otherInputParent.removeClass('d-none');
};

const hideOtherInput = input => {
  const $otherInputParent = $(input).parents('.row').next('.row');
  const $otherInput = $otherInputParent.find('input[id^="users_profile_legal_total_"][id$="_other"]');

  $otherInputParent.addClass('d-none');
  $otherInput.val('');
  $otherInput.next('div[id$="-invalid-feedback"]').text("");
};

$(document).ready(() => {
  const $form = $('form#legal-profile-form');

  if ($form.length === 0 || getQueryStringParameter("id") !== "financial_situation") {
    return;
  }

  const $showRadio = $form.find('input[id$="_more_than_1_b"]');
  const $hideRadio = $form.find('input:not([id$="_more_than_1_b"]):not([id$="_other"])');
  const $numberInputs = $form.find('input[id$="_other"]');

  showNumberInputsOnLoad($numberInputs);
  $hideRadio.on('change', e => hideOtherInput($(e.target)));
  $showRadio.on('change', e => showOtherInput($(e.target)));
})
