export const getQueryStringParameter = (parameterName) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(parameterName);
}

export const showInvalidFeedback = () => {
  const invalidFeedbackDiv = $('div[id$="-invalid-feedback"]');

  invalidFeedbackDiv.each(function() {
    if ($(this).text()) {
      $(this).parents('.form-group').removeClass('d-none');
    }
  })
};

export const showCheckedInputsOnLoad = (inputs) => {
  inputs.each((_index, el) => {
    if ($(el).is(':checked')) {
      $(el).parents('.form-group').removeClass('d-none');
    }
  });
};

export const showNumberInputsOnLoad = (inputs) => {
  inputs.each((_index, el) => {
    if ($(el).val() !== '') {
      $(el).parents('.form-group').removeClass('d-none');
    }
  });
};

export const clearInputField = (inputElement) => {
  switch (inputElement.type) {
    case "radio":
    case "checkbox":
      inputElement.checked = false;
      break;
    case "file":
      let $label = $(inputElement).siblings("label");

      if ($label.length != 0) {
        let labelText = $label.data("text") || "";

        $label.text(labelText);
      }
    default:
      inputElement.value = null;
  }
};

export const clearSelectField = (selectElement) => {
  $("option[selected]", selectElement).each((_, option) => {
    option.selected = false;
  });
};
