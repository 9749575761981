import {
  getQueryStringParameter,
  showInvalidFeedback,
  showCheckedInputsOnLoad,
  showNumberInputsOnLoad
} from '../../../helpers';

const americanLawEntityHandler = (radio, value) => {
  const entityDictionary = {
    true: 'input[id^="users_profile_legal_entity_category_"]',
    false: 'input[id^="users_profile_legal_entity_fatca_"]'
  }

  const boolVal = value == 'true';
  const $showInput = $(entityDictionary[boolVal]);
  const $hideInput = $(entityDictionary[!boolVal]);

  $showInput.parents('.form-group').removeClass('d-none');
  $hideInput.parents('.form-group').addClass('d-none');
  $hideInput.prop('checked', false);
};

const reportingFiHandler = prop => {
  const $giinNumberParent = $('#users_profile_legal_giin_number').parents('.form-group')

  if (prop === 'show') {
    $giinNumberParent.removeClass('d-none');
  } else {
    $giinNumberParent.addClass('d-none');
    $giinNumberParent.find('input').val('');
  }
};

$(document).ready(() => {
  if (getQueryStringParameter("id") !== "fatca") {
    return;
  }

  showInvalidFeedback();

  const $giinNumber = $('#users_profile_legal_giin_number')
  showNumberInputsOnLoad($giinNumber);

  const $entityCategory = $('input[id^="users_profile_legal_entity_category_"]');
  const $entityFatca = $('input[id^="users_profile_legal_entity_fatca_"]');
  showCheckedInputsOnLoad($.merge($entityCategory, $entityFatca));

  const $reportingFi = $('#users_profile_legal_entity_fatca_reporting_fi');
  const $nonReportingFi = $reportingFi
    .parents('.form-group:first')
    .find('input:not([id="users_profile_legal_entity_fatca_reporting_fi"])');

  $reportingFi.on('change', () => reportingFiHandler('show'));
  $nonReportingFi.on('change', () => reportingFiHandler('hide'))

  const $americanLawEntityTrue = $('#users_profile_legal_american_law_entity_true');
  const $americanLawEntityFalse = $('#users_profile_legal_american_law_entity_false');
  $americanLawEntityTrue.on('change', e => americanLawEntityHandler($(e.target), $(e.target).val()));
  $americanLawEntityFalse.on('change', e => americanLawEntityHandler($(e.target), $(e.target).val()));
});
