import { getQueryStringParameter } from '../../../helpers';

const preventFormSubmission = (e, $id) => {
  $id.removeClass('d-none');

  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
};

const allowFormSubmission = ($form, $id) => {
  $id.removeClass('show');
  $id.addClass('d-none');
  $form.unbind('submit');
};

const sizeCategorizationCheck = ($id, $form, e = undefined) => {
  const $checkedNatureCategorization = $('input[id^="users_profile_legal_nature_categorization_"]:checked');

  if ($checkedNatureCategorization.val() === 'none') {
    preventFormSubmission(e, $id);
  } else {
    allowFormSubmission($form, $id);
  }
};

const legalCategorizationCheck = ($id, $form, e = undefined) => {
  const $checkedSizeCategorization = $('#sizeCategorization').find('input[type="radio"][value="false"]:checked');

  if ($checkedSizeCategorization.length >= 2) {
    preventFormSubmission(e, $id);
  } else {
    allowFormSubmission($form, $id);
  }
};

const showDetailsInput = $el => {
  const $valueParentDiv = $($el.data('toggle'));

  if ($el.val() == "true") {
    $valueParentDiv.removeClass('d-none');
  } else {
    $valueParentDiv.addClass('d-none');
  }
};

const showInvalidFeedback = () => {
  const $invalidFeedbackDiv = $('div[id$="-invalid-feedback"]');
  const $parentDiv = $invalidFeedbackDiv

  $invalidFeedbackDiv.each(function() {
    const $parentDiv = $(this).parents('div[id$="Categorization"]');

    if ($(this).text()) {
      $parentDiv.removeClass('d-none');
      $(this).parents('.form-group').removeClass('d-none');
    }
  })
};

const showHiddenInputsOnLoad = () => {
  const natureCategorization = $('input[id^="users_profile_legal_nature_categorization_"]')

  if (natureCategorization.val() !== 'none') return;

  ['#sizeCategorization', '#legalCategorization'].forEach(el => {
    if ($(el).find('input:checked').length > 0) {
      $(el).removeClass('d-none');
    }
  })
}

const showProfessionalClientWarning = () => {
  const fiscalCountry = $('#users_profile_legal_fiscal_country').val();
  const checkedNatureCategorization = $('input[id^="users_profile_legal_nature_categorization_"]:checked').val();
  const checkedSizeCategorizationLength = $('#sizeCategorization').find('input[type="radio"][value="true"]:checked').length;
  const checkedLegalCategorizationLength = $('#legalCategorization').find('input[type="radio"][value="true"]:checked').length;
  const condition = checkedNatureCategorization !== 'none' || checkedSizeCategorizationLength >= 2 || checkedLegalCategorizationLength >= 2;

  if (condition) {
    $('#professional_client_warning').removeClass('d-none');
    $('#french_non_professional_client_warning').addClass('d-none');
  } else {
    if (fiscalCountry === 'FR') {
      $('#french_non_professional_client_warning').removeClass('d-none');
      $('#professional_client_warning').addClass('d-none');
    }
  }
}

const hideSizeAndLegalInputs = target => {
  if (target.val() !== 'none') {
    $('#sizeCategorization, #legalCategorization').addClass('d-none');
  }
}

const showValueInputsOnLoad = () => {
  const valueInputs = $('#sizeCategorization').find('input[id^="users_profile_legal_"][id$="_value"]');
  valueInputs.push($('#users_profile_legal_financial_profession_details'));

  valueInputs.each((_index, el) => {
    if ($(el).val() !== '') {
      $(el).parents('.form-group').removeClass('d-none');
    }
  })
}

const clearFields = () => {
  $('#users_profile_legal_balance_sheet_greater_than_20_m_false').on('click', () => {
    $('#users_profile_legal_balance_sheet_value').val('');
  });

  $('#users_profile_legal_equity_greater_than_2_m_false').on('click', () => {
    $('#users_profile_legal_equity_value').val('');
  });

  $('#users_profile_legal_turnover_greater_than_40_m_false').on('click', () => {
    $('#users_profile_legal_turnover_value').val('');
  });

  $('#users_profile_legal_has_exercised_financial_profession_false').on('click', () => {
    $('#users_profile_legal_financial_profession_details').val('');
  });
}

$(document).ready(() => {
  const $form = $('form#legal-profile-form');

  if ($form.length === 0 || getQueryStringParameter("id") !== "profile") {
    return;
  }

  const $sizeId = $('#sizeCategorization');
  const $legalId = $('#legalCategorization');
  const $natureCategorization = $('input[id^="users_profile_legal_nature_categorization_"]')
  const $sizeCategorization = $('#sizeCategorization').find('input[type="radio"]');
  const $legalCategorization = $('#legalCategorization').find('input[type="radio"]');
  const $financialProfessionInput = $('input[id^="users_profile_legal_has_exercised_financial_profession_"]');

  showInvalidFeedback();
  showHiddenInputsOnLoad();
  showProfessionalClientWarning();
  showValueInputsOnLoad();
  clearFields();

  $form.on('submit', (e) => {
    if ($sizeId.hasClass('d-none')) {
      sizeCategorizationCheck($sizeId, $form, e);
    }

    if ($legalId.hasClass('d-none')) {
      legalCategorizationCheck($legalId, $form, e);
    }
  });

  $natureCategorization.on('change', e => {
    if (e.target.id != 'users_profile_legal_nature_categorization_none') {
      if ($('#sizeCategorization').find('input[type="radio"][value="false"]:checked').length >= 2) {
        $('input[name="users_profile_legal[has_exercised_financial_profession]"]:checked').prop("checked", false);
        $('input[name="users_profile_legal[has_financial_instruments_portfolio]"]:checked').prop("checked", false);
        $('input[name="users_profile_legal[has_realized_financial_operations]"]:checked').prop("checked", false);

        $('#users_profile_legal_financial_profession_details').val('');
      }

      $('input[name="users_profile_legal[balance_sheet_greater_than_20_m]"]:checked').prop("checked", false)
      $('input[name="users_profile_legal[equity_greater_than_2_m]"]:checked').prop("checked", false)
      $('input[name="users_profile_legal[turnover_greater_than_40_m]"]:checked').prop("checked", false)

      $('#users_profile_legal_balance_sheet_value').val('');
      $('#users_profile_legal_equity_value').val('');
      $('#users_profile_legal_turnover_value').val('');
    }

    showProfessionalClientWarning();
    hideSizeAndLegalInputs($(e.target));
    sizeCategorizationCheck($sizeId, $form);
  });

  $sizeCategorization.on('change', e => {
    showProfessionalClientWarning();
    showDetailsInput($(e.target));
  });

  $legalCategorization.on('change', () => showProfessionalClientWarning());
  $financialProfessionInput.on('change', e => showDetailsInput($(e.target)))
});
