import toastr from "toastr";
import { getQueryStringParameter } from '../../../helpers';

const noneInputHandler = $input => {
  const $otherInputs = $input.parents('.form-group').find('input:not([id$="_none"])');
  const $otherInputsDisabled = $otherInputs.attr('disabled');

  if ($otherInputsDisabled){
    $otherInputs.attr('disabled', false);
  } else {
    $otherInputs.attr('disabled', true);
    $otherInputs.prop('checked', false);
  }
};

const checkInputsOnChange = $form => {
  const $checkedInputs = $form.find('input:checked');

  if($checkedInputs.length > 0) {
    $form.unbind('submit');
  } else {
    $form.on('submit', e => checkInputsOnSubmit(e, $(e.target)));
  }
};

const checkInputsOnSubmit = (e, $form) => {
  const $checkedInputs = $form.find('input:checked');

  if ($checkedInputs.length === 0) {
    e.preventDefault();
    e.stopPropagation();
    toastr.error('Veuillez cocher au moins une réponse par question');
  }
}

$(document).ready(() => {
  const $form = $('form#legal-profile-form');

  if ($form.length === 0 || getQueryStringParameter("id") !== "knowledge") {
    return;
  }

  const $noneIputs = $form.find('input[id$="_none"]');
  const $noneCheckedIputs = $form.find('input[id$="_none"]:checked');
  const $inputs = $form.find('input');

  noneInputHandler($noneCheckedIputs);
  $form.on('submit', e => checkInputsOnSubmit(e, $form));
  $inputs.on('change', () => checkInputsOnChange($form))
  $noneIputs.on('change', e => noneInputHandler($(e.target)));
});
