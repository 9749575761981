import { getQueryStringParameter } from '../../../helpers';

const showBeneficiaryCardOnLoad = ($button) => {
  $('input:not([id$="__destroy"]):not([name*="_destroy]"]):not([type="hidden"]):not([type="radio"])', ".beneficiary-card")
    .filter(function() { return this.value.length !== 0 })
    .parents('.beneficiary-card')
    .filter(function() { return $('input[id$="__destroy"][name*="_destroy]"]:checked', this).length === 0 })
    .removeClass('d-none');

  const $visibleCards = $('.beneficiary-card:visible');

  if ($visibleCards.length === 4) {
    $button.addClass('d-none');
  }
};

const showFirstBeneficiaryHiddenCard = ($target) => {
  const $hiddenCard = $('.beneficiary-card:hidden:first');
  const $visibleCards = $('.beneficiary-card:visible');
  const $destroyInput = $hiddenCard.find('input[id$="__destroy"]');

  $hiddenCard.removeClass('d-none');
  $destroyInput.prop('checked', false);

  if ($visibleCards.length === 3) {
    $target.addClass('d-none');
  }
};

const destroyBeneficiaryCard = ($target, $button) => {
  const $visibleCards = $('.beneficiary-card:visible');

  $target.parents('.beneficiary-card').addClass('d-none');

  if ($visibleCards.length <= 4 &&  $button.hasClass('d-none')) {
    $button.removeClass('d-none');
  }
};

$(document).ready(() => {
  if (getQueryStringParameter("id") !== "beneficiaries") {
    return;
  }

  const $addBeneficiary = $('#addBeneficiary');
  const $destroyBeneficiary = $('input[id^="users_profile_legal_beneficiaries_attributes_"][id$="__destroy"]');

  showBeneficiaryCardOnLoad($addBeneficiary);
  $addBeneficiary.on('click', e => showFirstBeneficiaryHiddenCard($(e.target)));
  $destroyBeneficiary.on('click', e => destroyBeneficiaryCard($(e.target), $addBeneficiary));
});
