const toggleField = ($checkBox, $field) => {
  if ($checkBox.is(':checked')) {
    $field.removeClass('d-none');
  } else {
    $field.addClass('d-none');
  }
}

const toggleTiesToPubliclyTradedCompaniesField = () => {
  toggleField($('#users_profile_natural_has_ties_to_publicly_traded_companies_true'), $('#ties-to-publicly-traded-companies-fields'));
};

const toggleSharesInCompaniesCompaniesField = () => {
  toggleField($('#users_profile_natural_has_shares_in_companies_true'), $('#shares-in-companies-companies-fields'));
};

const togglePolicallyExposedPersonField = () => {
  toggleField($('#users_profile_natural_is_politically_exposed_person_true'), $('#is-politically-exposed-person-fields'));
};

const toggleCloseToPolicallyExposedPersonField = () => {
  toggleField($('#users_profile_natural_is_close_to_politically_exposed_person_true'), $('#is-close-politically-exposed-person-fields'));
};

$(document).ready(() => {
  toggleSharesInCompaniesCompaniesField();
  $('body').on(
    'change',
    'input[type="radio"][name="users_profile_natural[has_shares_in_companies]"]',
    toggleSharesInCompaniesCompaniesField
  );

  toggleTiesToPubliclyTradedCompaniesField();
  $('body').on(
    'change',
    'input[type="radio"][name="users_profile_natural[has_ties_to_publicly_traded_companies]"]',
    toggleTiesToPubliclyTradedCompaniesField
  );

  togglePolicallyExposedPersonField();
  $('body').on(
    'change',
    'input[type="radio"][name="users_profile_natural[is_politically_exposed_person]"]',
    togglePolicallyExposedPersonField
  );

  toggleCloseToPolicallyExposedPersonField();
  $('body').on(
    'change',
    'input[type="radio"][name="users_profile_natural[is_close_to_politically_exposed_person]"]',
    toggleCloseToPolicallyExposedPersonField
  );
});
