const toggleFiscalResidenceFields = () => {
  if ($('#users_profile_natural_has_fiscal_residence_true').is(':checked')) {
    $('#fiscal-residence-fields').removeClass('d-none');
  } else {
    $('#fiscal-residence-fields').addClass('d-none');
  }
};

$(document).ready(() => {
  toggleFiscalResidenceFields();
  $('body').on('change', 'input[type="radio"][name="users_profile_natural[has_fiscal_residence]"]', toggleFiscalResidenceFields);
});
